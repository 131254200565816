import React, { useRef, useEffect, useContext } from "react";
import { Comment, Typography, Empty } from "antd";
import moment from "moment";

import User from "../../Hooks/Logged";

const { Text } = Typography;

/**
 * Componente básico para mostrar una lista de mensajes.
 *
 * @param {Array} data - Lista de mensajes a mostrar.
 */
function MessagesList({ data = [] }) {
	
	const messagesContainerRef = useRef(null);
	const user = useContext(User);
	
	const formatDate = (date) => moment(date).format("LLL");

	/**
	 * Hace scroll automático hacia el final de la lista.
	 */
	const scrollToBottom = () => {
		const container = messagesContainerRef.current;
		if (container) {
			container.scrollTop = container.scrollHeight; // Desplaza el scroll al final
		}
	};

	// Efecto para hacer scroll cada vez que cambien los mensajes
	useEffect(() => {
		scrollToBottom();
	}, [data]);

	return (
		<div className="messages-list" ref={messagesContainerRef}>
			{data.length > 0 ? (
				data.map((item) => (
					<Comment
						key={item?._id}
						className="message-item"
						author={
						  	item.usuario ? (
						    	item.usuario._id === user._id ? (
						      		<Text strong style={{ color: "#000" }}>
						        		{item.usuario.nombre ?? item.usuario.nombres}
						      		</Text>
						    	) : (
						      		<Text strong style={{ color: "#2dda93" }}>SOPORTE</Text>
						    	)
						  	) : (
						    	<Text strong style={{ color: "#2dda93" }}>ZEUS</Text>
						  	)
						}
						datetime={formatDate(item.timestamp ?? item.createdAt)}
						content={<Text>{item.entrada || "Sin contenido"}</Text>}
					/>
				))
			) : (
				<Empty description="No hay mensajes disponibles" />
			)}
		</div>
	);
}

export default MessagesList;
