import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { User, SetUser } from './Hooks/Logged';
import Socket, { SetSocketContext } from './Hooks/Socket';
import axios from 'axios';
import { Auth } from './Routes/Middlewares/Auth';
import Routes from './Routes';
import io from 'socket.io-client';
import './App.css';
import './Styles/Global/global.scss';
import { Modal } from 'antd';

// Configuración global de Axios
axios.defaults.baseURL = process.env.REACT_APP_WEB_SERVICE;
axios.defaults.withCredentials = true;
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use((config) => {
    config.headers.Authorization = sessionStorage.getItem('token');
    return config;
});

axios.interceptors.response.use(
    (response) => {
        if (response?.headers?.authorization) {
            sessionStorage.setItem('token', response.headers.authorization);
        }
        return response;
    },
    (error) => {
        if (error.response?.status === 401) {
            if (window.location.href.includes('/admin') || window.location.href.includes('/customer')) {
                Modal.error({
                    title: 'Debe iniciar sesión para continuar',
                    content: 'Para poder acceder a Zeus, debe iniciar sesión',
                    cancelButtonProps: { style: { display: 'none' } },
                    okText: 'Ir a Iniciar Sesión',
                    onOk: () => window.location.replace('/')
                });
            }
        }
        return Promise.reject(error);
    }
);

// Helper para crear el socket
const createSocket = (oldSocket) => {
    if (oldSocket) {
        oldSocket.disconnect();
        oldSocket.close();
    }

    return io(process.env.REACT_APP_WEB_SERVICE, {
        extraHeaders: { Authorization: sessionStorage.getItem('token') },
        withCredentials: true
    });
};

/**
 * Componente principal de la aplicación
 */
class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            socket: null,
        };
    }

    componentDidMount() {
        const token = sessionStorage.getItem('token');
        if (token) {
            this.fetchLoggedUser(token);
        }
    }

    // Método para obtener el usuario logueado
    fetchLoggedUser = async (token) => {
        try {
            const { data } = await axios.get('/user/logged', {
                headers: { Authorization: token },
                params: { cliente: true },
            });
            this.setUser(data.data);
            this.setState({ socket: createSocket() })
        } catch (error) {
            console.error('Error al obtener usuario logueado:', error.response?.status);
        }
    };

    // Actualiza el estado del usuario
    setUser = (user) => {
        this.setState({ user });
    };

    // Actualiza el socket
    setSocket = (socket = this.state.socket) => {
        this.setState({ socket: socket });
    };

    render() {
        const { user, socket } = this.state;
        return (
            <BrowserRouter>
                <Socket.Provider value={socket}>
                    <SetSocketContext.Provider value={this.setSocket}>
                        <User.Provider value={user}>
                            <SetUser.Provider value={this.setUser}>
                                <Routes />
                            </SetUser.Provider>
                        </User.Provider>
                    </SetSocketContext.Provider>
                </Socket.Provider>
            </BrowserRouter>
        );
    }
}

export default App;
