import React, { Component, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Form, Input, Button, Modal, Divider, Typography, Spin, Checkbox, message } from 'antd';
import { User, SetUser } from '../../Hooks/Logged';
import { SetSocketContext } from '../../Hooks/Socket'; // Importar el contexto del socket
import axios from 'axios';
import FormatedInput from '../Widgets/Inputs/FormatedInput';
import io from 'socket.io-client'; // Importar socket.io-client

const { Title, Paragraph } = Typography;

// Función para crear un socket
const createSocket = () => {
    return io(process.env.REACT_APP_WEB_SERVICE, {
        extraHeaders: {
            Authorization: sessionStorage.getItem('token'),
        },
        withCredentials: true,
    });
};

/**
 * Componente de Login
 */
class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
        const { user, navigate } = this.props;
        if (user?.rol_id) {
            navigate('/admin/dashboard');
        } else if (user) {
            navigate('/customer/resume');
        }
    }

    /**
     * Maneja el envío del formulario de login
     */
    handleSubmit = async (values) => {
        this.setState({ loading: true });

        try {
            const { data, headers } = await axios.post('/login', values);
            const { setUser, setSocket, navigate, changeView } = this.props;

            sessionStorage.setItem('token', headers.authorization);
            axios.defaults.headers.common['Authorization'] = headers.authorization;

            setUser(data.user);

            // Crear el socket después de iniciar sesión
            const socket = createSocket();
            setSocket(socket); // Actualizar el contexto del socket

            // Redireccionar basado en el rol o estado del usuario
            if (!data.user.rol_id) {
                this.handleCustomerRedirect(data.user, changeView);
            } else {
                navigate('/admin/dashboard');
            }
        } catch (error) {
            const { response } = error;
            Modal.error({
                title: 'Error',
                content: response?.data?.message || 'Ocurrió un error al iniciar sesión',
            });
        } finally {
            this.setState({ loading: false });
        }
    };

    /**
     * Maneja la redirección de clientes según su estado
     */
    handleCustomerRedirect = (user, changeView) => {
        /** 
         * 0 -> Desactivado
         * 1 -> Normal 
         * 2 -> Lleno información básica
         * 3 -> Llenó información persona
         * 4 -> Llenó Dirección
         */
        switch (user.status) {
            case 0:
                message.error('No es posible iniciar sesión');
                break;
            case 1:
                this.redirectTo('/customer/resume');
                break;
            case 2:
                message.info("Es necesario completar tus datos")
                changeView(2)
                break;
            case 3:
                message.info('Es necesario completar tus datos');
                changeView(3);
                break;
            default:
                this.redirectTo('/customer/resume');
                break;
        }
    };

    /**
     * Redirecciona a la URL proporcionada
     */
    redirectTo = (to) => {
        this.props.navigate(to);
    };

    render() {
        const { loading } = this.state;
        const { openRecovery, changeView } = this.props;

        return (
            <Row justify="center">
                <Col span={24} className="login-form">
                    <Row justify="center">
                        <Col xs={23} md={18}>
                            <Title level={3} className="text-center">¡Bienvenido!</Title>
                            <Paragraph className="text-center">Para comenzar, inicia sesión o regístrate.</Paragraph>
                            <Spin spinning={loading}>
                                <Form
                                    initialValues={{ keep_session: true }}
                                    onFinish={this.handleSubmit}
                                    layout="vertical"
                                    className="login-form"
                                    requiredMark={false}
                                >
                                    <Form.Item
                                        name="email"
                                        label="Email"
                                        rules={[
                                            { required: true, message: 'Por favor ingresa tu email' },
                                            { pattern: /^[^A-Z]+$/, message: 'El email debe estar en minúsculas' },
                                        ]}
                                    >
                                        <FormatedInput trim multiple placeholder="E-mail" className="hm-login-input" />
                                    </Form.Item>
                                    <Form.Item
                                        label="Contraseña"
                                        name="password"
                                        rules={[{ required: true, message: 'Por favor ingresa tu contraseña' }]}
                                        extra={
                                            <Form.Item noStyle name="keep_session" valuePropName="checked">
                                                <Checkbox style={{ marginTop: 10 }}>Mantener la sesión iniciada</Checkbox>
                                            </Form.Item>
                                        }
                                    >
                                        <Input type="password" placeholder="Contraseña" className="hm-login-input" />
                                    </Form.Item>
                                    <div
                                        className="text-center text-purple hover"
                                        onClick={openRecovery}
                                    >
                                        ¿Olvidaste tu contraseña?
                                    </div>
                                    <div
                                        className="text-center hover text-purple"
                                        onClick={changeView}
                                    >
                                        ¿No tienes cuenta? ¡Regístrate ahora!
                                    </div>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" className="mt-2" block>
                                            Iniciar sesión
                                        </Button>
                                    </Form.Item>
                                </Form>
                                <Divider />
                            </Spin>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}

export default function LoginWrapper(props) {
    const user = useContext(User);
    const setUser = useContext(SetUser);
    const setSocket = useContext(SetSocketContext); // Obtener el contexto del socket
    const navigate = useNavigate();

    return <Login {...props} user={user} setUser={setUser} setSocket={setSocket} navigate={navigate} />;
}
