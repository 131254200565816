import React, { useEffect, useRef, useContext } from "react";
import { Comment, Typography, Empty } from "antd";
import moment from "moment";
import "moment/locale/es";

import User from "../../../Hooks/Logged";

moment.locale("es"); 

const { Text } = Typography;

/**
 * Componente básico para mostrar una lista de mensajes.
 *
 * @param {Array} data - Lista de mensajes a mostrar.
 */
function MessagesList({ data = [] }) {

	const messagesContainerRef = useRef(null);
	const user = useContext(User);

	/**
	 * Formatea la fecha en un formato legible.
	 * @param {string} date - Fecha del mensaje.
	 * @returns {string} Fecha formateada.
	 */
	const formatDate = (date) => {
		return moment(date).format("LLL");
	}

	/**
	 * Hace scroll automático hacia el final de la lista.
	 */
	const scrollToBottom = () => {
		const container = messagesContainerRef.current;
		if (container) {
			container.scrollTop = container.scrollHeight; // Desplaza el scroll al final
		}
	};

	// Efecto para hacer scroll cada vez que cambien los mensajes
	useEffect(() => {
		scrollToBottom();
	}, [data]);

	return (
		<div
			className="messages-list-admin mb-1"
			ref={messagesContainerRef}
		>
			{data.length > 0 ? (
				data.map((item) => (
					<Comment
						key={item?._id}
						className="message-item"
						author={
							item.usuario ? (
								<Text strong style={{color: item?.usuario?._id === user._id ? "#2dda93" : "#000"}}>{item.usuario.nombre ?? item.usuario.nombres}</Text>
							) : (
								<Text strong style={{color: "#2dda93"}}>ZEUS</Text>
							)
						}
						datetime={formatDate(item.timestamp ?? item.createdAt)}
						content={<Text>{item.entrada || "Sin contenido"}</Text>}
					/>
				))
			) : (
				<></>
			)}
		</div>
	);
}

export default MessagesList;
